'use client'

import Link from 'next/link'
import { redirect } from 'next/navigation'

export default function Home() {
  redirect('/login')
  return (
    <>
      <div className='mx-10 my-20'>
        <div className='text-center'>
          <h1 className='text-2xl font-bold'>COACHTECH LMSへようこそ</h1>
          <p>COACHTECH LMSでは次のような機能をご利用いただけます。</p>
        </div>

        <section className='sec sec01 mt-10'>
          <div className='container'>
            <div className='inner'>
              <h2 className='title text-center text-xl font-bold'>
                <div className='title__top'>生徒様の学習を支える3つのポイント</div>
                <div className='title__bottom'>3 point of support</div>
              </h2>
            </div>

            <div className=''>
              <div className='inner flex flex-col gap-4'>
                <ul className='item item01'>
                  <li className='item__left'>
                    <div className='item__left__title'>
                      <h3 className='text-xl font-bold'>
                        短時間でエラー解決！
                        <br />
                        <i>質問チャットサポート</i>
                      </h3>
                    </div>
                    <div className='item__left__txt'>
                      <p>
                        COACHTECHでは、生徒様からの質問に対して30分以内の回答を心がけています。質問対応時間は平日15時〜22時、土日10時〜22時です。
                        <br />
                        <br />
                        実際に、95%の質問に30分以内に回答しており、その平均は約10分となっております。
                        <br />
                        <br />
                        この質問チャットサポートでは、答えをすぐに教えるのではなく、問題解決の糸口を提示することによって、疑問解消はもちろん、生徒様の自己解決力向上を手助けします。
                      </p>
                    </div>
                  </li>
                  <div className='item__right'>
                    <img
                      src='/images/support_01.png'
                      alt='チャット画面'
                      width={600}
                      height={600}
                      className='mx-auto'
                    />
                  </div>
                </ul>
                <ul className='item item02'>
                  <li className='item__left'>
                    <div className='item__left__title'>
                      <h3 className='text-xl font-bold'>
                        あなたの<i>専属コーチ</i>が<br />
                        毎週フィードバック
                      </h3>
                    </div>
                    <div className='item__left__txt'>
                      <p>
                        あなたに合った専属コーチが1週間に1度面談を行います。週次面談では、1週間の学習進捗や現状の課題をヒアリングした上で学習のフィードバック、1週間の学習計画作成を行います。
                        <br />
                        <br />
                        コーチは、あなたの学習に最後まで寄り添います。技術的な質問だけでなくキャリア、学習中のお悩み等にもお答えし、あなたの不安を解消します。
                      </p>
                    </div>
                  </li>
                  <div className='item__right'>
                    <img
                      src='/images/support_02.png'
                      alt='フィードバッグ'
                      width={600}
                      height={600}
                      className='mx-auto'
                    />
                  </div>
                </ul>
                <ul className='item item03'>
                  <li className='item__left'>
                    <div className='item__left__title'>
                      <h3 className='text-xl font-bold'>
                        自己解決能力を養う
                        <br />
                        <i>アウトプット型教材</i>
                      </h3>
                    </div>
                    <div className='item__left__txt'>
                      <p>
                        プログラミングを”教える”よりも”考えてもらう”ことに重点を置いて作られています。
                        <br />
                        <br />
                        学習中に躓くことも想定されているため、難しいと感じる方も多いですが、卒業後や実務の中でも必要な自己解決力を身につけることができます。
                        <br />
                        <br />
                        また、章ごとに演習問題が用意されているなど、アプトプット学習のための教材となっております。
                      </p>
                    </div>
                  </li>
                  <div className='item__right'>
                    <img
                      src='/images/support_03.png'
                      alt='教材'
                      width={600}
                      height={600}
                      className='mx-auto'
                    />
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>

      <footer className='border-t border-gray-300 py-4 text-center'>
        <p>
          <Link href='/privacy'>プライバシーポリシー</Link>
        </p>
      </footer>
    </>
  )
}
